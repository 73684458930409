<template>
    <section>
      <div class="connect">
        <div class="connect__wrap">
          <div class="connect__info">
            <!-- <div class="connect__error-img"></div> -->
            <p>
              네트워크 상태가<br/>불안정하여 접속이<br/>끊겼습니다.
            </p>
            <p>
              네트워크 상태 확인 후<br/>재접속 해주세요.
            </p>
            <button class="connect__btn" @click="onConnectClick">확인</button>
          </div>
        </div>
      </div>
    </section>
</template>
<script>
import router from '../router/index.js'

export default {
    name: 'ServiceExit',
    props : {
      roomId: {default: null},
    },
    methods: {
        onConnectClick() {
            router.push("/rooms/" + this.roomId)
        }
    }
}
</script>