import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'

// createApp(App).mount('#app')
// createApp(App).use(router)
// new Vue({
//     router,
//     render: h => h(App),
//   }).$mount('#app')

const myApp = createApp(App);
myApp.use(router);
myApp.mount('#app');