<template>
    <section>
      <div class="connect">
        <div class="connect__wrap">
          <div class="connect__info">
            <div class="connect__error-img"></div>
            <p>
              ‘가치해’ 서비스가<br />종료 되었습니다.
            </p>
            <!-- <button class="connect__btn" @click="onExitClick">확인</button> -->
          </div>
        </div>
      </div>
    </section>
</template>
<script>
export default {
    name: 'ServiceExit',
    methods: {
        onExitClick() {
            alert('서비스 종료')
        }
    }
}
</script>