<template>
    <div class="home">
        '가치해' 테스용 페이지
        <!-- <div>
            <router-link to="/rooms/hitest">rooms</router-link>
        </div> -->
        <div>
            <input type="text" v-model="roomId" class="room-id" placeholder="방명을 입력하세요">
        </div>
        <div>
            <button class="btn"  @click="onEnterRoom">방입장</button>
        </div>
    </div>
</template>

<script>
import router from '../router/index.js'

export default {
     name: 'Home',
     data() {
        return {
            roomId: null,
        }
     },
     created() {
        const params = new Proxy(new URLSearchParams(window.location.search), {
          get: (searchParams, prop) => searchParams.get(prop),
        });
        if (params.roomId != null && params.roomId != undefined) {
            this.roomId = params.roomId
            router.push("/rooms/" + this.roomId)
        }
     },
     methods: {
        onEnterRoom() {
            if (this.roomId == null || this.roomId == undefined) {
                alert('입장할 방명을 다시 입력해주세요.')
            } else {
                router.push("/rooms/" + this.roomId)
            }
            
        }
    }
}
</script>