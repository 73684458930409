<template>
    <div>
        <div class="service_moving_container">
            <div class="loading_warp">
                <div class="loading_dot" v-for="(i) in 8" :key="i" 
                    :class="'d_' + i"
                    :style="{ backgroundColor: getDotColor(i) }"
                    ></div>
            </div>
            <div class="service_moving_text">서비스 페이지로 이동중</div>
            <div class="service_moving_sub">해당 페이지로 이동 중입니다.<br>조금만 기다려주세요.</div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ServiceLoading',
    data() {
        return {
            dotColor : [
                '#3448FF',

            ],
            start: 0,
            lodingHandler: null,
        }
    },
    mounted() {
        this.lodingHandler = setInterval( ()=> {
            if (this.start < 7) {
                this.start++
            } else {
                this.start = 0
            }
        }, 100)
    },
    unmounted() {
        clearInterval(this.lodingHandler)
    },
    methods: {
        getDotColor(i) {
            const index = i - this.start
            const data = index > 0 ? index-1: 7 + index;
            const alpha = 1 - (parseInt(data) * .1)
            return 'rgba(255, 255, 255, ' + alpha.toString() + ')'
        },
    },
}
</script>