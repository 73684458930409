<template>
  <service-loading v-if="isLoading" />
  <div class="container flex column" :class="[{hide: isLoading}, {webOn: webPageOpen}, {tableCell: isTableCell} ]" :style="{height: getPageHeight, overflowY: getPageOverflow}">
    <header v-if="isHeadList" class="hide">
      <input type="text" v-model="confName" :disabled="isConnected" />
      <button @click="onConnect" v-if="!isConnected">연결하기</button>
      <button @click="onDisConnectConference" v-if="isConnected">연결해제하기</button>
      <button @click="onRecordingStart" v-if="isConnected && !isRecording">녹화시작</button>
      <button @click="onRecordingStop" v-if="isConnected && isRecording">녹화중지</button>
      <button @click="onCreateLocalScreen" v-if="isConnected && !localScreen">화면공유적용</button>
      <button @click="onDestroyLocalScreen" v-if="isConnected && localScreen">화면공유해제</button>

      <div style="display:inline-block;" v-if="isConnected">
        <select id="camaraSource">
          <option v-for="(item, index) in cameraDevices" :key="index" :value="item.deviceId">{{item.label}}</option>
        </select>
        <button @click="onChangeCamera">카메라변경</button>
      </div>

      <div style="display:inline-block;" v-if="isConnected">
        <select id="micSource">
          <option v-for="(item, index) in micDevices" :key="index" :value="item.deviceId">{{item.label}}</option>
        </select>
        <button @click="onMicChange">마이크변경</button>
      </div>

      <div style="display:inline-block;" v-if="isConnected">
        <select id="speakerSource">
          <option v-for="(item, index) in speakerDevices" :key="index" :value="item.deviceId">{{item.label}}</option>
        </select>
        <button @click="onSpeakerChange">스피커변경</button>
      </div>
    </header>
    <!-- <div class="flex column" :style="{height: isTableCell ? 'auto': '100%'}"> -->
    <div class="flex column video">
      <!-- <div class="spotlight" :class="isHostView ? 'host': ''"> -->
      <div class="spotlight" :style="{height: shareVideoHeight + 'px'}">
        <!-- <video id="spotlight-video" :class="isHostView ? 'host': ''" autoplay playsInline></video> -->
        <video v-if="!isPlayAD" id="spotlight-video" :class="isHostView ? 'host': ''" autoplay playsInline></video>
        <video v-else id="spotlight-ad-video" :class="isHorizontalVideo ? 'host': ''" autoplay playsInline muted></video>
        <div class="btn-area" :class="[{hide: isLoading || isVodLoading}, {safari: isSafari}]" :style="{width: getBtnAreaWidth, bottom: getBtnAreaBottom}">
          <!-- <button class="btn" @click="openWebPage">{{ btnTitle }}</button> -->
          <!-- <button class="btn" @click="openWebPage"><i class="fa" :class="webPageOpen ? 'fa-close': 'fa-plus'"></i></button> -->
          <button class="btn" @click="openWebPage">
            <div :class="webPageOpen ? 'close': 'plus'"></div>
          </button>
        </div>
      </div>
      <div v-if="isMediaList" class="media-list">
        <div class="media-block" v-if="localMedia">
          <div class="participant-id">{{myId}}</div>
          <div class="local-container" @click="onClickSpotlight"></div>
        </div>

        <div
          class="media-block"
          v-for="(item, i) in remoteParticipants"
          :key="i"
          :id="item.participant.id"
          @click="onClickSpotlight"
        >
          <div class="participant-id">{{item.participant.id}}</div>
          <voice-meter :level="item.level" />
          <remote-video v-for="(video, j) in item.videos" :key="j" autoplay :remoteVideo="video" />
        </div>
      </div>
    </div>
    <div v-show="webPageOpen" class="partition"/>
    <div id="webPage" class="flex column web" :class="webPageOpen ? 'on': 'off'">
      <!-- <iframe  class="web-window" src="https://shopping.naver.com/home"></iframe> -->
    </div>
  </div>
</template>

<script>
import voiceMeter from './voiceMeter.vue';
import remoteVideo from './remoteVideo.vue';
import ConnectLive from '@connectlive/connectlive-web-sdk';
import router from '../router/index.js'
import ServiceLoading from '@/components/ServiceLoading.vue';

export default {
  name: 'Rooms',
  components: {
    voiceMeter,
    remoteVideo,
    ServiceLoading,
  },
  props : {
    roomId: {default: null},
  },
  created() {
    // alert('hi = ' + this.roomId)
  },
  mounted() {
    // alert('mounted = ' + this.roomId)
    document.documentElement.style.scrollBehavior = "smooth";
    this.$nextTick(async ()=>{
       this.onConnect()
    })
   
    this.visibilitychangeHandler = (e)=> {
      console.log('visibilitychange', document.visibilityState, e.type)
      const videoId = this.isPlayAD ? 'spotlight-ad-video': 'spotlight-video'
      // const videoId = 'spotlight-video'
      const vid = document.getElementById(videoId)
      if (document.visibilityState == 'visible') {
        vid?.play()
      } else if (document.visibilityState == 'hidden') {
        vid?.pause()
      }
    }
    window.addEventListener('orientationchange', (e)=> {
      console.log('orientationchange', e)
    })

    document.addEventListener("visibilitychange", this.visibilitychangeHandler);

    this.touchStartHandler = () => {
      if (this.isPlayAD) {
        const vid = document.getElementById('spotlight-ad-video')
        vid.muted = false
      }
    }
    document.addEventListener("touchstart", this.touchStartHandler);
  },
  unmounted() {
    if (this.connectTimeoutHandler) {
      clearTimeout(this.connectTimeoutHandler)
    }

    document.removeEventListener("visibilitychange", this.visibilitychangeHandler);
    document.removeEventListener("touchstart", this.touchStartHandler);
  },
  computed: {
    getBtnAreaWidth: function() {
      return window.innerWidth + 'px'
    },
    getVideoHeight: function() {
      // return window.innerHeight + 'px'
      // return this.isHostView ? window.innerHeight + 'px': 'auto'
      if (!this.isLoading) {
        // const vid = this.isPlayAD ? document.getElementById('spotlight-ad-video'): document.getElementById('spotlight-video')
        // if (vid.offsetWidth > vid.offsetHeight) {
        //   return window.innerHeight + 'px'
        // } else {
        //   return vid.offsetHeight + 'px'
        // }
        // alert('getVideoHeight = ' + this.shareVideoHeight)
        // return this.shareVideoHeight + 'px'

        if (this.isPlayAD) {
          return '640px'
        } else {
          return window.innerHeight + 'px'
        }
      }
      return 'auto'
    },
    getBtnAreaBottom: function() {
      if (!this.isLoading) {
        // const videoHeight = document.getElementsByClassName('spotlight')[0].offsetHeight
        // alert(this.shareVideoHeight + ":" + window.innerHeight + ":" + this.isHostView)
        if ( this.shareVideoHeight > window.innerHeight && !this.isHostView) {
          return (115 + ( this.shareVideoHeight - window.innerHeight)) + 'px'
        }
      }
      return '115px'
    },
    getPageHeight: function() {
      if (this.webPageOpen) {
        const tempHeight = this.shareVideoHeight < window.innerHeight ? this.shareVideoHeight: window.innerHeight

        // 공유영상과 웹페이지 경계선을 노출되지 않는 설정
        // return (tempHeight + 5 + document.getElementsByClassName('spotlight')[0].offsetHeight) + 'px'

        // 공유영상과 웹페이지 경계선을 노출하는 설정
        return (tempHeight + document.getElementsByClassName('spotlight')[0].offsetHeight) + 'px'
      }
      return '100%'
    },
    // isHorizontalVideo: function() { 
    //   alert (document.getElementById('spotlight-video').offsetHeight + ':' + document.getElementById('spotlight-video').offsetWidth)
    //   if (document.getElementById('spotlight-video').offsetHeight > document.getElementById('spotlight-video').offsetWidth) {
    //     return false
    //   }
    //   return true;
    // },
    getPageOverflow: function() {
      if (!this.isLoading) {
        // const videoHeight = document.getElementById('spotlight-video').offsetHeight
        if (this.shareVideoHeight > window.innerHeight || this.webPageOpen) {
          return 'scroll'
        }
      }
      return 'hidden'
    },
    isTableCell: function() {
      if (this.webPageOpen) {
        return this.shareVideoHeight < window.innerHeight ? true: false
      }
      return false
    },
    isSafari: function() {
      // const agent = window.navigator.userAgent.toLowerCase()
      // alert(agent)
      // return agent.indexOf('safari') > -1
      return true
    },
  },
  watch: {
    // shareVideo: function() {
    //   this.activateShareUser();
    // }
  },
  data() {
    return {
      confName: this.roomId,
      conf: null,
      localMedia: null,
      localScreen: null,
      remoteParticipants: [],
      isConnected: false,
      myId: '',
      cameraDevices: [],
      micDevices: [],
      speakerDevices: [],
      audioCheckInterval: -1,
      isRecording: false,
      shareUser: null,
      shareVideo: null,
      isMediaList: false,
      isHeadList: false,
      isLoading: true,
      isHostView: false,
      isHostVodPlay: false,
      webPageOpen: false,
      btnTitle: '웹페이지 열기',
      isUseHostView: false,
      shareVideoHeight: 0,
      isHorizontalVideo: false,
      isPlayAD: false,
      isVodLoading: false,
      visibilitychangeHandler: null,
      touchStartHandler: null,
      adVodListOld: [
        // require('@/assets/media/gnigame-info.mp4'),
        'https://with-shop.gnifrix.com/media/vertical-ad-01.mp4',
        'https://with-shop.gnifrix.com/media/vertical-ad-02.mp4',
        'https://with-shop.gnifrix.com/media/vertical-ad-03.mp4',
        // require('@/assets/media/PexelsVideos1409899.mp4')
      ],
      adVodList: [
        // {
        //   type: 'horizontal',
        //   url: require('@/assets/media/gnigame-info.mp4'),
        // },
        {
          type: 'vertical',
          url: 'https://with-shop.gnifrix.com/media/vertical-ad-01.mp4',
        },
        {
          type: 'vertical',
          url: 'https://with-shop.gnifrix.com/media/vertical-ad-02.mp4',
        },
        {
          type: 'vertical',
          url: 'https://with-shop.gnifrix.com/media/vertical-ad-03.mp4',
        }
      ],
      adVodListIndex: 0,
      connectTimeoutHandler: null,
    };
  },
  methods: {
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min; //최댓값은 제외, 최솟값은 포함
    },
    openWebPage() {
      if (!this.webPageOpen) {
        const iframe = document.createElement('iframe')
        iframe.src = 'https://shopping.naver.com/home'
        iframe.style.width = '100%'
        iframe.style.height = '100%'
        iframe.style.border =  '0px';
        iframe.style.padding =  '0px';

        document.getElementById('webPage').appendChild(iframe)
        iframe.classList.add("web-window");
        iframe.id = 'webPage'
        
        this.webPageOpen = true
        this.btnTitle = '웹페이지 닫기'
        this.scrollDownPage()
      } else {
        const iframe = document.getElementsByClassName('web-window')[0]
        document.getElementById('webPage').removeChild(iframe)

        this.btnTitle = '웹페이지 열기'
        this.srollUpPage(true)
      }
    },
    scrollDownPage() {
      this.$nextTick(async ()=>{
          const videoHeight = document.getElementsByClassName('spotlight')[0].offsetHeight
          document.documentElement.scrollTop = videoHeight > window.innerHeight ? videoHeight: window.innerHeight
        })
    },
    srollUpPage(isPageClose) {
      this.$nextTick(async ()=>{
          document.documentElement.scrollTop = 0
           if (isPageClose) {
            setTimeout( ()=> {
              this.webPageOpen = false
            }, 200)
           }
        })
    },
    
    playShareScreen(evt) {
      this.isPlayAD = false
      this.isVodLoading = true
      const participant = this.remoteParticipants.find(item => item.participant.id === evt.senderId);
      const shareIndex = participant.videos.findIndex(video => { return video.extraValue == 'screen' || video.extraValue == 'share'})
      if (shareIndex > -1) {
        this.$nextTick(async ()=>{
          participant.videos[shareIndex].setQuality('h')
          this.isHostView = false
          const vid = document.getElementById('spotlight-video')
          vid.srcObject = participant.videos[shareIndex].getMediaStream()

          vid.onplaying = ()=> {
            this.isVodLoading = false
            this.shareVideoHeight = document.getElementById('spotlight-video').offsetHeight
            vid.onplaying = undefined
          }

          this.srollUpPage()
          // 로컬오디오 장치 기능을 활성화 한다.
          // this.localMedia.audio.setEnabled(true) 
        })
      }
    },
    actionWhenStopShareScreen(evt, isRemoteUnpublished) {
      if (!this.isUseHostView) {
        if (evt.message && evt.message.split('|')[1]) {
          this.adVodListIndex = parseInt(evt.message.split('|')[1])
        } else {
          this.adVodListIndex = this.getRandomInt(0, this.adVodList.length)
        }
        this.playADVod(isRemoteUnpublished)
      }
      //   this.isHostVodPlay = true
      // if (this.isHostVodPlay) {
      //   this.playHostVod(evt)
      //   this.isHostVodPlay = false
      // } else {
      //   this.playADVod(isRemoteUnpublished)
      //   this.isHostVodPlay = true
      // }
    },
    playHostVod(evt) {
      const participant = this.remoteParticipants.find(item => item.participant.id === evt.senderId || evt.remoteParticipant.id);
      const cameraIndx = participant.videos.findIndex(video => { return video.extraValue == 'camera'})
      if (cameraIndx > -1) {
        // Host의 카메라 영상을 공유
        this.$nextTick(async ()=>{
          participant.videos[cameraIndx].setQuality('h')
          this.isHostView = true
          document.getElementById('spotlight-video').srcObject = participant.videos[cameraIndx].getMediaStream()
        })
      } else {
        // 스크린에 스트림을 재생하지 않음
        // document.getElementById('spotlight-video').srcObject = undefined;
        this.$nextTick(async ()=>{
          participant.videos[0].setQuality('h')
          this.isHostView = true
          document.getElementById('spotlight-video').srcObject = participant.videos[0].getMediaStream()
        })
      }
    },
    playADVod(isRemoteUnpublished) {
      this.isPlayAD = true
      this.isVodLoading = true
      this.$nextTick(async ()=>{
        this.isHostView = true
        const vodUrl = this.adVodList[this.adVodListIndex].url
        this.isHorizontalVideo = this.adVodList[this.adVodListIndex].type == 'horizontal' ? true: false
        const vid = document.getElementById('spotlight-ad-video')
        // const vid = document.getElementById('spotlight-video')

        vid.srcObject = undefined
        vid.pause()

        if (isRemoteUnpublished) {
          setTimeout(()=>{ vid.src = vodUrl }, 300)
        } else {
          vid.src = vodUrl
        }
        vid.loop = 'loop'
        vid.play()
        vid.onplaying = ()=> {
          if (this.isHorizontalVideo) {
            this.shareVideoHeight = window.innerHeight
          } else {
            this.shareVideoHeight = vid.offsetHeight
          }
          this.isVodLoading = false
          // vid.muted = false
          vid.onplaying = undefined
          setTimeout(()=> {
            const agent = window.navigator.userAgent.toLowerCase()
            if (agent.indexOf('iphone') > -1 && agent.indexOf('safari') > -1) {
              console.log(agent)
            } else {
              this.touchStartHandler()
            }
          }, 1000)
        }

        // 로컬오디오 장치 기능을 비활성화 한다.
        // this.localMedia.audio.setEnabled(false)

        // 동영상 광고 소리를 안나게 처리
        // vid.muted = true

        this.srollUpPage()
      })
    },
    async onConnect() {
      await this.onProvisioning();
      await this.onCreateLocalMedia();
      await this.onCreateConference();
      await this.conf.connect(this.confName);

      this.myId = this.conf.localParticipant.id;
      this.isConnected = true;
      this.conf.publish([this.localMedia]);

      this.audioCheckInterval = window.setInterval(()=>{
        const audioLevels = this.conf.getRemoteAudioLevels();
        audioLevels.remoteParticipants.forEach((audioParticipant)=>{
          const participant = this.remoteParticipants.find(participant => participant.participant.id === audioParticipant.remoteParticipant.id);

          let level = parseFloat(audioParticipant.level.toFixed(3));
          if(level > 0.15) {
            level = 0.15;
          }

          if(participant.level) {
            participant.level = level / 0.15 * 100;
          }
        });
      }, 250);

      // Todo: 타임아웃 시간 조정 필요
      this.connectTimeoutHandler =  setTimeout(() => {
        if (this.isLoading) {
          router.push('/error/' + this.roomId)
          this.onDisConnectConference()
        }
      },  60000);
    },
    async onProvisioning() {
      await ConnectLive.signIn({
        // serviceId: '2022GFRXLX6A',
        // serviceKey: '2022GFRXLX6AIR7S',
        // secret: '4X7Puj6a741W8eD5',
        serviceId: '2022GFRXLX6A',
        serviceSecret: '2022GFRXLX6AIR7S:4X7Puj6a741W8eD5',
      });
    },
    async onUnpublish() {
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = () => {
          window.history.pushState(null, "", window.location.href);
      };
    },
    async onCreateLocalMedia() {
      this.localMedia = await ConnectLive.createLocalMedia({
        audio: true,
        video: true
      })
      // .then((localMedia)=>{
      //     로컬 미디어 (마이크) 장치를 비활성 한다.
      //     localMedia.audio.setEnabled(false)
      // });

      this.$nextTick(async ()=>{
        const video = this.localMedia.video.attach();
        video.className = 'local-video';
        video.style.width = '100%';
        document.querySelector('.local-container').appendChild(video);

        this.cameraDevices = await this.localMedia.getCameraDevices();

        console.log('cameraDevices = ', this.cameraDevices);

        this.micDevices = await this.localMedia.getMicDevices();

        this.speakerDevices = await this.localMedia.getSpeakerDevices();
      });
    },
    async onCreateLocalScreen() {
      this.localScreen = await ConnectLive.createLocalScreen({
        audio: true,
        video: true
      });
      this.localScreen.video.setExtraValue('screen');

      const video = this.localScreen.video.attach();
      video.className = 'local-video';
      video.style.width = '100%';
      document.querySelector('.local-container').appendChild(video);

      this.conf.publish([this.localScreen]);
    },
    async onCreateConference() {
      this.conf = ConnectLive.createRoom();

      this.conf.on('error', ()=> {
        this.isLoading = true
        router.push('/error/' + this.roomId)
        this.onDisConnectConference()
      });

      this.conf.on('connected', (evt)=>{
        evt.remoteParticipants.forEach(async participant => {
          let remoteVideos = [];
          const unsubscribedVideos = participant.getUnsubscribedVideos();
          if (unsubscribedVideos.length) {
            const videoIds = unsubscribedVideos.map(video => video.getVideoId());
            remoteVideos = await this.conf.subscribe(videoIds);
          }

         const shareIndex = remoteVideos.findIndex(video => { return video.extraValue == 'screen' || video.extraValue == 'share'})
         if (shareIndex > -1) {
            this.shareUser = participant
            console.log("video.extraValue = host ", participant.id)
            this.shareVideo = remoteVideos[shareIndex]
            this.activateShareUser()
            // this.$nextTick(async ()=>{
            //   remoteVideos[shareIndex].setQuality('h');
            //   document.getElementById('spotlight-video').srcObject = remoteVideos[shareIndex].getMediaStream()
            // })
         }
        // remoteVideos.forEach( (video) => {
        //   console.log('video.extraValue = ', video.extraValue);
        //   if (video.extraValue == 'screen' || video.extraValue == 'share') {
        //     this.shareUser = participant;
        //     console.log('video.extraValue shareUser = ', this.shareUser)
        //     this.shareVideo = video;
        //     return;
        //   }
        // });

          // console.log('remoteVideos = ', remoteVideos, shareIndex);

        //  if (shareIndex > 0) {
        //   alert('shareIndex = ' + shareIndex)
        //  }
          this.remoteParticipants.push({
            participant: participant,
            videos: remoteVideos,
            level: 0
          });

          // console.log('remoteParticipants = ', this.remoteParticipants);
        });
      });

      this.conf.on('participantEntered', (evt)=>{
        this.remoteParticipants.push({
          participant: evt.remoteParticipant,
          videos: [],
          level: 0
        });
      });

      this.conf.on('participantLeft', evt => {
        const index = this.remoteParticipants.findIndex(item => item.participant.id === evt.remoteParticipantId);
        if (this.shareUser.id == evt.remoteParticipantId) {
          this.isLoading = true
          router.push('/exit')
          this.onDisConnectConference()
        }
        this.remoteParticipants.splice(index, 1);
      });

      this.conf.on('remoteVideoPublished', async evt => {
        const remoteVideos = await this.conf.subscribe([evt.remoteVideo.videoId]);
        if (remoteVideos.length) {
          const participant = this.remoteParticipants.find(item => item.participant.id === evt.remoteParticipant.id);
          console.log('remoteVideoPublished = ', participant);
          if(participant) {
            participant.videos = participant.videos.concat(remoteVideos);
            const shareIndex = remoteVideos.findIndex(video => { return video.extraValue == 'screen' || video.extraValue == 'share'})
            if (shareIndex > -1) {
                this.isPlayAD = false
                this.shareUser = participant.participant
                // this.shareVideo = remoteVideos[shareIndex]
                // this.activateShareUser()
                remoteVideos[shareIndex].setQuality('h')
                this.isHostView = false
                document.getElementById('spotlight-video').srcObject = remoteVideos[shareIndex].getMediaStream()
                // this.shareVideoHeight = document.getElementsByClassName('spotlight')[0].offsetHeight
                // this.isLoading = false

                const vid = document.getElementById('spotlight-video')
                vid.onplaying = ()=> {
                  // this.shareVideoHeight = document.getElementsByClassName('spotlight')[0].offsetHeight
                  this.shareVideoHeight = document.getElementById('spotlight-video').offsetHeight
                  this.isLoading = false
                  vid.onplaying = undefined
                }
            }
          }
        }
      });

      this.conf.on('remoteVideoUnpublished', evt => {
        const participant = this.remoteParticipants.find(item => item.participant.id === evt.remoteParticipant.id);
        
          if(participant && !this.isLoading) {
            console.log('remoteVideoUnpublished = ', participant.videos[0])
            const shareIndex = participant.videos.findIndex(video => { return video.extraValue == 'screen' || video.extraValue == 'share'})
            if (shareIndex > -1 && !this.isHostView) {
              console.log('스크린 공유 중지')
              this.shareVideo = null;
              this.actionWhenStopShareScreen(evt, true)
            }
            participant.videos = participant.videos.filter((remoteVideo)=>{
              return remoteVideo.videoId !== evt.remoteVideo.videoId;
            });
          }
      });

      this.conf.on('userMessage', evt => {
        if (evt.message.indexOf('host-share-stop') > -1  && !this.isHostView) {
          this.actionWhenStopShareScreen(evt)
        } else if (evt.message == 'host-share-start' && this.isHostView) {
          this.playShareScreen(evt)
        }
      });
    },
    onDisConnectConference() {
      this.conf.disconnect();

      this.localMedia.stop();
      this.localMedia.video.detach();

      this.localMedia = null;

      if(this.localScreen) {
        this.localScreen.stop();
        this.localScreen.video.detach();
        this.localScreen = null;
      }

      this.isConnected = false;
      this.myId = '';
      this.remoteParticipants = [];
      window.clearInterval(this.audioCheckInterval);
      this.audioCheckInterval = -1;

      ConnectLive.signOut();
    },
    async onDestroyLocalScreen() {
      this.localScreen.video.detach();
      this.localScreen.stop();

      this.conf.unpublish([this.localScreen]);

      this.localScreen = undefined;
    },
    async onChangeCamera() {
      this.localMedia.video.detach();

      const camaraSource = document.getElementById('camaraSource');
      await this.localMedia.switchCamera(camaraSource.value);

      const video = this.localMedia.video.attach();
      video.className = 'local-video';
      video.style.width = '100%';
      document.querySelector('.local-container').appendChild(video);
    },
    async onMicChange() {
      const micSource = document.getElementById('micSource');
      await this.localMedia.switchMic(micSource.value);
    },
    async onSpeakerChange() {
      const speakerSource = document.getElementById('speakerSource');
      await this.conf.switchSpeaker(speakerSource.value);
    },
    onClickSpotlight(event) {
      const target = event.target;
      // const remoteParticipant = this.conf.remoteParticipants.find(participant => participant.id === target.dataset.participantid);
      const remoteParticipant = this.conf.remoteParticipants.find(participant => participant.id === target.dataset.participantid);
      
      if (remoteParticipant) {
        const t = remoteParticipant.getVideo(parseInt(target.dataset.videoid));
        t.setQuality('h');
      }

      document.getElementById('spotlight-video').srcObject = target.srcObject;
    },
    async activateShareUser() {
      if (this.shareVideo) {
        // const t = this.shareUser.getVideo(parseInt(this.shareVideo.videoId));
        // t.setQuality('h');
        this.shareVideo.setQuality('h');
        this.isHostView = false
        document.getElementById('spotlight-video').srcObject = this.shareVideo.getMediaStream();

        const vid = document.getElementById('spotlight-video')
        vid.onplaying = ()=> {
          this.shareVideoHeight = document.getElementById('spotlight-video').offsetHeight
          this.isLoading = false
          vid.onplaying = undefined
        }
      } else {
        document.getElementById('spotlight-video').srcObject = undefined;
      }
      
    },
    async onRecordingStart() {
      await this.conf.unpublish([this.localMedia]);

      await this.conf.publish([this.localMedia], true);

      this.isRecording = true;
    },
    async onRecordingStop() {
      await this.conf.unpublish([this.localMedia]);

      await this.conf.publish([this.localMedia]);

      this.isRecording = false;
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  /* background-color: #101523; */
  color: #fff;
}

input {
  line-height: 1.5;
  padding: 8px;
  border: 1px solid hsl(0, 0%, 10%);
  color: #fff;
  background: hsl(0, 0%, 14%);
  transition: background-color 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25),
    transform 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25);
}

button {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 0;
  cursor: pointer;
  margin: 10px;
  padding: 10px;
}

.btn-area {
    height: 0px;
    position: relative;
    /* bottom: 115px; */
    right: 10px;
    display: flex;
    justify-content: flex-end;
}
.btn-area.safari {
  height: unset;
}
.btn-area .btn {
  background: unset;
  width: auto;
  height: auto;
  padding: 0px;
  margin: 0px;
}

.btn {
  background: #1d70bc;
  border-radius: 5px;
  width: 36px;
  height: 36px;
  font-size: 14px;
}

/* .btn:hover {
  background: #193252;
} */

video {
  cursor: pointer;  
}

.flex {
  display: flex;
  flex: 2;
  height: 100%;
}
.flex.webOn {
  height: 200%;
}
.flex.webOn.tableCell {
  display: table-column;
}

.flex.column {
  flex-direction: column;
  align-items: center;
}

.flex.column.video {
  height: auto;
  /* padding-bottom: 5px;
  background: #000000; */
}

.flex.column.web {
  width: 100%;
}
.flex.column.web.on {
  visibility: visible;
}
.flex.column.web.off {
  visibility: hidden;
}

.flex.row {
  flex-direction: row;
}

.partition {
  width: 100%;
  height: 5px;
  background: #000000;
}

header {
  min-height: 55px;
  padding: 3px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-align: left;
}

.spotlight {
  width: 100%;
  padding: 0px;
}
.spotlight.host {
  height: 100%;
}

.spotlight video {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.spotlight.host video {
  width: auto;
}
.spotlight video.host {
  height: 100%;
}


.media-list {
  width: 300px;
  padding: 10px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  overflow: auto;
}

.media-list .media-block {
  position: relative;
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #c9c9c9;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  cursor: pointer;
}

.participant-id {
  position: absolute;
  bottom: 5px;
  left: 5px;
  color: #fff;
  font-size: 12px;
}

.web-window {
  border: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
</style>
