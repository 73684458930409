// import Vue from 'vue';
// import VueRouter from 'vue-router';
import { createWebHistory, createRouter } from "vue-router"
import Rooms from '../components/Rooms'
import HelloWorld from '../components/HelloWorld'
import Home from '../components/Home'
import ServiceExit from '../components/ServiceExit'
import ServiceError from '../components/ServiceError'

const routes = [
    { path: '/', component: Home},
    { path: '/home', component: HelloWorld },
    { path: '/rooms/:roomId', component: Rooms, props: true },
    { path: '/exit', component: ServiceExit },
    { path: '/error/:roomId', component: ServiceError, props: true },
    // { path: '/about', component: About },
]

const router = createRouter({
    mode: "history",
	history : createWebHistory(),
    routes
});

export default router;